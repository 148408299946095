import { FC, useCallback, useEffect, useState } from 'react';
import cn from 'classnames';
import { AnimatePresence, motion } from 'framer-motion';
import { useIntl } from 'react-intl';
import { ConnectError } from '@bufbuild/connect';

import { Portal } from '@/shared/ui/Portal/Portal.tsx';
import { CORS_POLICY, MODAL_ANIMATION_DURATION } from '@/shared/config';
import { Icon } from '@/shared/ui';
import { useAppDispatch, useAppSelector } from '@/shared/hooks';

import { albumModel } from '@/entities/album';
import { ModalID, modalModel, RemoveModal } from '@/entities/modal';
import { getImageSrc } from '@/entities/assets';
import { authModel } from '@/entities/auth';

import { errorToastCaller } from '@/features/errorToastCaller';

import styles from './PhotoSlider.module.scss';

type PhotoSliderProps = {
  className?: string;
  testID?: string;
};

export const PhotoSlider: FC<PhotoSliderProps> = (props) => {
  const { className, testID } = props;
  const { formatDate, formatMessage } = useIntl();
  const dispatch = useAppDispatch();

  const { visible, data } = useAppSelector(
    modalModel.selectors.selectDentalPhotoSliderModal,
  );

  const album = useAppSelector((state) =>
    albumModel.selectors.selectById(state, data?.albumID),
  );

  const [currentIndex, setCurrentIndex] = useState(0);
  const [isRemoveModalOpen, setIsRemoveModalOpen] = useState(false);

  useEffect(() => {
    if (data?.activePhoto && album?.StudyAssetIDs) {
      const index = album.StudyAssetIDs.findIndex(
        (id) => id === data.activePhoto,
      );

      if (index !== -1) {
        setCurrentIndex(index);
      }
    }
  }, [data?.activePhoto, album?.StudyAssetIDs]);

  const onClose = useCallback(
    () => dispatch(modalModel.actions.closeModal(ModalID.DentalPhotoSlider)),
    [dispatch],
  );

  const handleNext = useCallback(() => {
    if (!album?.StudyAssetIDs?.length) {
      return;
    }

    setCurrentIndex((prev) =>
      prev === album.StudyAssetIDs.length - 1 ? prev : prev + 1,
    );
  }, [album?.StudyAssetIDs?.length]);

  const handlePrev = useCallback(() => {
    if (!album?.StudyAssetIDs?.length) {
      return;
    }

    setCurrentIndex((prev) => (prev === 0 ? prev : prev - 1));
  }, [album?.StudyAssetIDs?.length]);

  useEffect(() => {
    const handleKeyPress = (event: KeyboardEvent) => {
      if (!visible) return;

      if (event.key === 'ArrowRight') {
        handleNext();
      }
      if (event.key === 'ArrowLeft') {
        handlePrev();
      }
      if (event.key === 'Escape') {
        onClose();
      }
    };

    window.addEventListener('keydown', handleKeyPress);

    return () => window.removeEventListener('keydown', handleKeyPress);
  }, [visible, handleNext, handlePrev, onClose]);

  const handleRemove = useCallback(async () => {
    if (album?.ID && album?.StudyIDs) {
      const activeStudyID = album.StudyIDs[currentIndex];

      if (activeStudyID) {
        try {
          await dispatch(authModel.thunks.refresh({}));

          const { DentalPhotoAlbum } = await dispatch(
            albumModel.thunks.deleteStudiesFromAlbum({
              AlbumID: album.ID,
              StudyIDs: activeStudyID ? [activeStudyID] : [],
            }),
          ).unwrap();

          if (album.StudyIDs.length > 1) {
            setCurrentIndex((prevIndex) =>
              prevIndex > 0 ? prevIndex - 1 : prevIndex + 1,
            );
          }

          if (album.StudyIDs.length === 1) {
            const { DeletedAlbumID } = await dispatch(
              albumModel.thunks.deleteAlbum({ AlbumID: album.ID }),
            ).unwrap();

            if (DeletedAlbumID) {
              dispatch(albumModel.actions.removeOne(DeletedAlbumID));
            }

            onClose();
          } else if (DentalPhotoAlbum) {
            dispatch(albumModel.actions.setNewestOne(DentalPhotoAlbum));
          }
        } catch (error) {
          errorToastCaller(error as ConnectError);
        }
      }
    }
  }, [album?.ID, album?.StudyIDs, currentIndex, dispatch, onClose]);

  const handleDownload = async () => {
    if (album?.StudyAssetIDs) {
      const activePhoto = album.StudyAssetIDs[currentIndex];

      try {
        await dispatch(authModel.thunks.refresh({}));

        const response = await fetch(getImageSrc(activePhoto, 'original'), {
          credentials: CORS_POLICY,
        });

        if (response.ok) {
          const blob = await response.blob();
          const downloadLink = Object.assign(document.createElement('a'), {
            href: window.URL.createObjectURL(blob),
            download: `photo_${activePhoto}.jpeg`,
          });
          downloadLink.click();
        }
      } catch (error) {
        errorToastCaller(error as ConnectError);
      }
    }
  };

  return (
    <AnimatePresence key="slider-animate-presence">
      {visible && (
        <Portal>
          <div className={cn(styles.container, className)} data-testid={testID}>
            <motion.div
              className={styles.overlay}
              onClick={onClose}
              key="slider-overlay"
              transition={{ duration: MODAL_ANIMATION_DURATION }}
              initial={{ opacity: 0 }}
              animate={{ opacity: 1 }}
              exit={{ opacity: 0 }}
            />

            <motion.div
              className={cn(styles.container)}
              key="slider"
              exit="exit"
              variants={{
                initial: { scale: 0, opacity: 0 },
                exit: { scale: 0, opacity: 0 },
                open: {
                  scale: 1,
                  opacity: 1,
                  transition: { duration: MODAL_ANIMATION_DURATION },
                },
                closed: {
                  scale: 0,
                  opacity: 0,
                  transition: { duration: MODAL_ANIMATION_DURATION },
                },
              }}
              initial="initial"
              animate={visible ? 'open' : 'closed'}
            >
              <header className={styles.header}>
                <button className={styles.close} onClick={onClose}>
                  <Icon name="close" size={40} />
                </button>
              </header>

              <div className={styles.body}>
                {album?.StudyAssetIDs?.[currentIndex] && (
                  <motion.img
                    key={`optimized_${album.StudyAssetIDs[currentIndex]}`}
                    className={styles.photo}
                    src={getImageSrc(
                      album.StudyAssetIDs[currentIndex],
                      'optimized',
                    )}
                    alt={`Фото ${currentIndex + 1}`}
                    initial={{ opacity: 0, x: 0 }}
                    animate={{ opacity: 1, x: 0 }}
                    exit={{ opacity: 0 }}
                    transition={{ duration: 0.2 }}
                  />
                )}
              </div>

              <footer className={styles.footer}>
                <div className={cn(styles.fileName, 'p1')}>
                  {formatDate(album?.Time?.toDate())} {album?.Name}
                </div>

                <div className={styles.icons}>
                  <Icon
                    className={styles.downloadIcon}
                    name="download"
                    size={32}
                    onClick={handleDownload}
                  />
                  <Icon
                    className={styles.deleteIcon}
                    name="delete"
                    size={32}
                    onClick={() => setIsRemoveModalOpen(true)}
                  />
                </div>
              </footer>

              <div
                className={cn(
                  styles.arrowLeft,
                  currentIndex === 0 && styles.disabled,
                )}
                onClick={handlePrev}
              >
                <Icon name="arrowDown" />
              </div>
              <div
                className={cn(
                  styles.arrowRight,
                  currentIndex === (album?.StudyAssetIDs?.length ?? 0) - 1 &&
                    styles.disabled,
                )}
                onClick={handleNext}
              >
                <Icon name="arrowDown" />
              </div>
            </motion.div>
          </div>
        </Portal>
      )}

      <RemoveModal
        key="remove-modal"
        isOpen={isRemoveModalOpen}
        message={formatMessage({
          id: 'dentalPhoto.removePhotoModal.message',
          defaultMessage: 'This action is irreversible',
        })}
        title={formatMessage({
          id: 'dentalPhoto.removePhotoModal.title',
          defaultMessage: 'Remove this photo',
        })}
        onCancel={() => setIsRemoveModalOpen(false)}
        onRemoveCallback={handleRemove}
      />
    </AnimatePresence>
  );
};
