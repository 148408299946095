import { FC } from 'react';
import cn from 'classnames';
import { FormattedMessage } from 'react-intl';
import { Control, Controller } from 'react-hook-form';
import { components } from 'react-select';

import { Select } from '@/shared/ui';

import { additionalInfoSelectStyles } from '../AdditionalInfoSelect/AdditionalInfoSelect.styles';
import { useGetInterestOptions } from '../../hooks/useGetInterestOptions';
import { AdditionalInfoFormPayload } from '../../config/types';
import styles from '../../Steps.module.scss';

type FocusOfInterestStepProps = {
  control: Control<AdditionalInfoFormPayload>;
  className?: string;
  testID?: string;
};

export const FocusOfInterestStep: FC<FocusOfInterestStepProps> = (props) => {
  const { control, className, testID } = props;

  const interestOptions = useGetInterestOptions();

  return (
    <div className={cn(styles.container, className)} data-testid={testID}>
      <h3 className={cn(styles.title, 'h3')}>
        <FormattedMessage
          id="additionalInfo.focusOfInterest"
          defaultMessage="What is your focus of interest for using dental AI?"
        />
      </h3>

      <div className={styles.selectWithText}>
        <Controller
          control={control}
          name="FocusOfInterest"
          render={({ field: { ref, value, name, onBlur, onChange } }) => (
            <Select
              options={interestOptions}
              isRequired
              isMulti
              ref={ref}
              value={value}
              name={name}
              onBlur={onBlur}
              onChange={onChange}
              styles={additionalInfoSelectStyles}
              components={{
                MultiValueLabel: components.MultiValueLabel,
                MultiValueRemove: components.MultiValueRemove,
              }}
            />
          )}
        />

        <p className={cn(styles.severalOptionsText, 'p3')}>
          <FormattedMessage
            id="additionalInfo.focusOfInterest.severalOptions"
            defaultMessage="You can choose several options here"
          />
        </p>
      </div>
    </div>
  );
};
