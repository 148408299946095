import { trim } from 'ramda';

import { useAppDispatch } from '@/shared/hooks';

import { reportsModel, useCheckReportSignature } from '@/entities/reports';
import { toothModel } from '@/entities/tooth';

export const useAddNewComment = () => {
  const dispatch = useAppDispatch();

  const { checkReportSignature } = useCheckReportSignature();

  return async (toothID: string, newComment: string) => {
    checkReportSignature({
      toothID,
      onSignatureChecked: async () => {
        dispatch(
          toothModel.actions.setNewCommentOptimistic({
            toothID,
            comment: newComment,
          }),
        );

        const response = await dispatch(
          reportsModel.thunks.setReportToothComment({
            ToothID: toothID,
            Comment: trim(newComment),
          }),
        ).unwrap();

        if (response.Tooth) {
          dispatch(toothModel.actions.setNewestOne(response.Tooth));
        }
      },
    });
  };
};
