import { FC, useState } from 'react';
import { FormattedMessage, useIntl } from 'react-intl';

import { useAppDispatch, useAppSelector } from '@/shared/hooks';
import { Modal } from '@/shared/ui';

import { ModalID, modalModel } from '@/entities/modal';

import { useRevokeSharedPatient } from '../../hooks/useRevokeSharedPatient';
import { useCancelInvitation } from '../../hooks/useCancelInvitation';

import styles from './RevokeSharedPatientModal.module.scss';

export const RevokeSharedPatientModal: FC = () => {
  const dispatch = useAppDispatch();

  const { formatMessage } = useIntl();

  const [loading, setLoading] = useState(false);

  const {
    visible,
    data: { patientID, sharedPatientDoctorID, invitationID, isInviteAccepted },
  } = useAppSelector((state) => state.modal.RevokeSharedPatientModal);

  const revokeSharedPatient = useRevokeSharedPatient();

  const cancelInvitation = useCancelInvitation();

  const handleClose = () => {
    dispatch(modalModel.actions.closeModal(ModalID.RevokeSharedPatientModal));
  };

  const revokeShared = async () => {
    setLoading(true);

    try {
      if (isInviteAccepted) {
        await revokeSharedPatient({
          patientID,
          sharedPatientDoctorID,
        });
      } else {
        await cancelInvitation({
          invitationID,
        });
      }
    } finally {
      setLoading(false);

      handleClose();
    }
  };

  return (
    <Modal
      title={
        <FormattedMessage
          id="revokeSharedPatient.title"
          defaultMessage="Do you to revoke access to this patient?"
        />
      }
      isOpen={visible}
      onCancel={handleClose}
      className={styles.modal}
      containerClassName={styles.modalContainer}
      okButtonText={formatMessage({
        id: 'global.revoke',
        defaultMessage: 'Revoke',
      })}
      okButtonProps={{ danger: true, loading }}
      onOk={revokeShared}
    >
      <p className="p2">
        <FormattedMessage
          id="revokeSharedPatient.description"
          defaultMessage="The dental professional will no longer have access to the patient profile"
        />
      </p>
    </Modal>
  );
};
