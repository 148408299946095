import { FC, useMemo } from 'react';
import { useIntl } from 'react-intl';
import cn from 'classnames';

import {
  Organization,
  OrganizationContacts,
} from '@/shared/api/protocol-ts/model/dto_organization_pb';
import { Description, Icon } from '@/shared/ui';
import { useAppSelector } from '@/shared/hooks';

import { getImageSrc } from '@/entities/assets';
import { useCountriesOptions } from '@/entities/organization';
import { userModel } from '@/entities/user';

import styles from './PreviewHeader.module.scss';

const getContactsLocation = (
  country: string,
  contacts?: OrganizationContacts,
) => {
  const locationParts: string[] = [];

  if (!contacts) {
    return '';
  }

  if (contacts.ZipCode) {
    locationParts.push(contacts.ZipCode);
  }

  if (country) {
    locationParts.push(country);
  }

  if (contacts.Region) {
    locationParts.push(contacts.Region);
  }

  if (contacts.City) {
    locationParts.push(contacts.City);
  }

  return locationParts.join(', ').trim();
};

type PreviewHeaderProps = {
  title: string;
  date: string;
  patientName: string;
  patientDateOfBirth: string;
  organization?: Organization;
  blackAndWhiteMode?: boolean;
};

export const PreviewHeader: FC<PreviewHeaderProps> = (props) => {
  const {
    title,
    date,
    patientName,
    patientDateOfBirth,
    organization,
    blackAndWhiteMode,
  } = props;

  const { formatMessage } = useIntl();

  const locale = useAppSelector(userModel.selectors.selectUserLocale);

  const countryOptions = useCountriesOptions(locale);

  const organizationCountry = organization?.Contacts?.Country;

  const currentCountry = useMemo(
    () =>
      countryOptions?.find(
        (countryOption) => countryOption.value === organizationCountry,
      )?.label ?? '',
    [countryOptions, organizationCountry],
  );

  const location = getContactsLocation(currentCountry, organization?.Contacts);

  return (
    <div className={styles.container}>
      <div className={styles.clinicRow}>
        {organization?.LogoAssetID && (
          <img
            className={cn(
              styles.orgLogo,
              blackAndWhiteMode && styles.blackOrgLogo,
            )}
            src={getImageSrc(organization.LogoAssetID, 'thumbnail')}
            alt={formatMessage({
              id: 'imgAltText.organizationLogo',
              defaultMessage: 'Organization Logo',
            })}
          />
        )}

        <div className={styles.clinicInfo}>
          <h5 className={cn(styles.clinicTitle, 'h7')}>{organization?.Name}</h5>
          {location && <p className="p3">{location}</p>}
          <p className="p3">{organization?.Contacts?.Address}</p>
          <p className="p3">{organization?.Contacts?.Phones[0]}</p>
          <p className="p3">{organization?.Contacts?.Emails[0]}</p>
          <p className="p3">{organization?.Contacts?.WebSite}</p>
        </div>
      </div>

      <div className={styles.reportInfo}>
        <h1 className="h4">{title}</h1>

        <div className={styles.reportInfoDetails}>
          <Description
            className={styles.description}
            contentClassName={styles.printText}
            hideColon
            label={
              <Icon name="user" size={13} className={styles.patientIcon} />
            }
          >
            {patientName}
          </Description>

          <Description
            className={styles.description}
            contentClassName={styles.printText}
            labelClassName={styles.printText}
            label={formatMessage({
              id: 'patientInfo.DoB',
              defaultMessage: 'DoB',
            })}
          >
            {patientDateOfBirth}
          </Description>

          <Description
            className={styles.description}
            contentClassName={styles.printText}
            labelClassName={styles.printText}
            label={formatMessage({
              id: 'patientInfo.studyDate',
              defaultMessage: 'Study Date',
            })}
          >
            {date}
          </Description>
        </div>
      </div>
    </div>
  );
};
