import { FC, useEffect, useState } from 'react';
import cn from 'classnames';
import { FormattedMessage, useIntl } from 'react-intl';
import { SubmitHandler, useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';

import { useMedia } from '@/shared/hooks';
import { Button, Icon } from '@/shared/ui';
import companyName from '@/shared/assets/images/Marketing/companyName.svg';
import focusOfInterest from '@/shared/assets/images/Marketing/focusOfInterest.svg';
import ownerSpecialization from '@/shared/assets/images/Marketing/ownerSpecialization.svg';
import { trackPageView } from '@/shared/lib';
import { OrganizationOnboardingQuestionnaire_V1 } from '@/shared/api/protocol-ts/api/marketing/dto_questionnaire_pb';

import { QuestionnaireSteps } from '@/entities/marketing/config/types';

import { CongratsModal } from '@/widgets/CongratsModal';

import { OwnerSpecializationStep } from './ui/OwnerSpecialization/OwnerSpecialization';
import { CompanyNameStep } from './ui/CompanyNameStep/CompanyNameStep';
import { FocusOfInterestStep } from './ui/FocusOfInterestStep/FocusOfInterestStep';
import { AllStepsSchema, additionalInfoFormSchema } from './config/formSchema';
import styles from './AdditionalInformation.module.scss';
import { AdditionalInfoFormPayload } from './config/types';
import { stepImagesAltText } from './config/i18n';
import { useAdditionalInformation } from './hooks/useAdditionalInformation';

const images: Record<QuestionnaireSteps, string> = {
  [QuestionnaireSteps.CompanyName]: companyName,
  [QuestionnaireSteps.FocusOfInterest]: focusOfInterest,
  [QuestionnaireSteps.OwnerSpecialization]: ownerSpecialization,
};

type AdditionalInformationProps = {
  className?: string;
  testID?: string;
};

// TODO: refactor this component
// The idea is to make it simpler by making separate widget for each step since we send data to the backend on each step.
// Move switch by step logic on the page level to control which widget we should render.
// Each widget have form with own validation schema, picture and text.
export const AdditionalInformation: FC<AdditionalInformationProps> = (
  props,
) => {
  const { className, testID } = props;

  const { formatMessage } = useIntl();

  const [step, setStep] = useState<QuestionnaireSteps>(
    QuestionnaireSteps.CompanyName,
  );

  const { isMobile } = useMedia();

  const {
    isQuestionnaireEmpty,
    currentOrganizationName,
    currentStep,
    hasCurrentFocusOfInterestData,
    isFirstStepCompanyName,
    isLastStepFocusOfInterest,
    requiresTextInputFieldValidation,
    stepsDefaultValues,
    userFirstName,
    changeOrganizationName,
    createNewOrganization,
    getOrganizationOnboardingQuestionnaire,
    handleOpenCongratsModal,
    putOrganizationOnboardingQuestionnaire,
  } = useAdditionalInformation(step);

  const {
    handleSubmit,
    control,
    reset,
    formState: { isSubmitting, isValid, isDirty },
  } = useForm<AdditionalInfoFormPayload>({
    // @ts-expect-error WARN: Payload and schema in conflict. Refactor is needed.
    resolver: yupResolver<AllStepsSchema>(additionalInfoFormSchema[step]),
    mode: 'onChange',
  });

  const isTextInputFieldInvalid = !isValid && !isDirty;

  // TODO: [1|m] remove useless memoization
  const currentStepComponent = () => {
    switch (step) {
      case QuestionnaireSteps.CompanyName:
        return <CompanyNameStep control={control} />;
      case QuestionnaireSteps.OwnerSpecialization:
        return <OwnerSpecializationStep control={control} />;
      case QuestionnaireSteps.FocusOfInterest:
        return <FocusOfInterestStep control={control} />;
      default:
        return null;
    }
  };

  useEffect(() => {
    trackPageView('AdditionalInformation Page Viewed');
  }, []);

  // Set default
  useEffect(() => {
    if (hasCurrentFocusOfInterestData) {
      handleOpenCongratsModal();

      return;
    }

    if (isQuestionnaireEmpty) {
      getOrganizationOnboardingQuestionnaire();
    }

    setStep(currentStep);

    reset(stepsDefaultValues);
  }, [currentOrganizationName, isQuestionnaireEmpty]);

  const handleChangeStep = (stepNumber: QuestionnaireSteps) => {
    if (stepNumber < 1) {
      return;
    }
    setStep(stepNumber);
  };

  const nextStep = () => {
    if (step === QuestionnaireSteps.FocusOfInterest) {
      handleOpenCongratsModal();

      return;
    }

    handleChangeStep(step + 1);
  };

  const backStep = () => {
    handleChangeStep(step - 1);
  };

  const onSubmit: SubmitHandler<AdditionalInfoFormPayload> = async (data) => {
    if (isFirstStepCompanyName) {
      const { CompanyName } = data;

      if (
        currentOrganizationName !== undefined &&
        currentOrganizationName !== CompanyName
      ) {
        changeOrganizationName(CompanyName);
      }

      if (currentOrganizationName === undefined) {
        createNewOrganization(CompanyName);
      }
      // For the proper functioning of the form and completion of the registration,
      // the FocusOfInterest should only be submitted on the last step
    } else if (isLastStepFocusOfInterest) {
      const {
        OwnerSpecialization,
        CompanyProfile,
        CBCTMachineType,
        CBCTImagingSoftware,
        XRayImagingSoftware,
        FocusOfInterest,
      } = data;

      putOrganizationOnboardingQuestionnaire({
        // OwnerSpecialization is string
        OwnerSpecialization: OwnerSpecialization.toString(),
        CompanyProfile,
        CBCTMachineType,
        CBCTImagingSoftware,
        XRayImagingSoftware,
        FocusOfInterest,
      } as OrganizationOnboardingQuestionnaire_V1);
    } else {
      const {
        OwnerSpecialization,
        CompanyProfile,
        CBCTMachineType,
        CBCTImagingSoftware,
        XRayImagingSoftware,
      } = data;

      putOrganizationOnboardingQuestionnaire({
        // OwnerSpecialization is string
        OwnerSpecialization: OwnerSpecialization.toString(),
        CompanyProfile,
        CBCTMachineType,
        CBCTImagingSoftware,
        XRayImagingSoftware,
        FocusOfInterest: [] as string[],
      } as OrganizationOnboardingQuestionnaire_V1);
    }

    nextStep();
  };

  return (
    <div className={cn(styles.container, className)} data-testid={testID}>
      <div className={styles.wrapper}>
        {isFirstStepCompanyName && (
          <h5 className={cn(styles.header, 'h5')}>
            <FormattedMessage
              id="additionalInfo.title"
              defaultMessage="Nice to meet you, {name}!"
              values={{
                name: userFirstName,
              }}
            />
          </h5>
        )}
        <div className={styles.main}>
          <form
            id="additionalInfo"
            onSubmit={handleSubmit(onSubmit)}
            className={styles.form}
          >
            {currentStepComponent()}

            <div
              className={cn(
                styles.actions,
                isFirstStepCompanyName && styles.justifyContentEnd,
              )}
            >
              {!isFirstStepCompanyName && (
                <button
                  type="button"
                  onClick={backStep}
                  className={styles.backButton}
                >
                  <Icon
                    className={styles.backButtonIcon}
                    name="arrowDown"
                    size={32}
                  />

                  <span className="p1">
                    <FormattedMessage id="global.back" defaultMessage="Back" />
                  </span>
                </button>
              )}

              <Button
                variant="primary"
                type="submit"
                size={isMobile ? 'medium' : 'large'}
                loading={isSubmitting}
                disabled={
                  requiresTextInputFieldValidation
                    ? isTextInputFieldInvalid
                    : !isValid
                }
              >
                <FormattedMessage id="global.next" defaultMessage="Next" />
              </Button>
            </div>
          </form>

          <img
            src={images[step]}
            alt={formatMessage(stepImagesAltText[step])}
          />
        </div>
      </div>

      <CongratsModal />
    </div>
  );
};
