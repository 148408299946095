import { OrganizationOnboardingQuestionnaire_V1 } from '@/shared/api/protocol-ts/api/marketing/dto_questionnaire_pb';
import { DefaultInitialState } from '@/shared/config';

export enum QuestionnaireSteps {
  CompanyName = 1,
  OwnerSpecialization = 2,
  FocusOfInterest = 3,
}

export type MarketingInitialState = DefaultInitialState & {
  questionnaire: OrganizationOnboardingQuestionnaire_V1;
};
