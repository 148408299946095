import { UserLocale } from '../config';

const DEFAULT_AVAILABLE_LANGUAGES: UserLocale[] = [
  'ru',
  'en-US',
  'de',
  'it',
  'es',
  'fr',
  'pt',
];

export const getAvailableLanguages = (): UserLocale[] =>
  __REACT_APP_AVAILABLE_LANGS__
    ? (__REACT_APP_AVAILABLE_LANGS__.split(',') as UserLocale[])
    : DEFAULT_AVAILABLE_LANGUAGES;
