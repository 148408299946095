import { useEffect } from 'react';
import { useLocation, useNavigate } from 'react-router';
import { Code, ConnectError } from '@bufbuild/connect';

import { useAppDispatch, useAppSelector } from '@/shared/hooks';
import api from '@/shared/api/api';
import { PATHS } from '@/shared/config';
import { debug } from '@/shared/lib/debug';

import { billingModel } from '@/entities/billing';
import { organizationModel } from '@/entities/organization';
import { accessModel } from '@/entities/access';
let abortController: AbortController;

export const useOrganizationSettingsStream = () => {
  const organizationID = useAppSelector(
    organizationModel.selectors.selectCurrentOrganizationID,
  );

  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const location = useLocation();

  const openOrganizationSettingsStream = async () => {
    dispatch(billingModel.actions.setLoading('pending'));

    abortController = new AbortController();

    try {
      const organizationSettingsStream =
        api.organization.organizationSettingsStream(
          { OrganizationID: organizationID },
          { signal: abortController.signal },
        );

      for await (const { Update } of organizationSettingsStream) {
        switch (Update.case) {
          case 'HistoricalOrganization': {
            dispatch(organizationModel.actions.setOne(Update.value));
            break;
          }

          case 'UpdatedOrganization': {
            dispatch(organizationModel.actions.setNewestOne(Update.value));
            break;
          }

          case 'HistoricalInvitation': {
            dispatch(accessModel.actions.setOne(Update.value));
            break;
          }

          case 'UpdatedInvitation': {
            dispatch(accessModel.actions.setOne(Update.value));
            break;
          }
        }
      }
    } catch (error) {
      const connectError = ConnectError.from(error);

      if (
        connectError.code === Code.Unknown &&
        connectError.message.includes('network')
      ) {
        debug('Network error detected, attempting to restart stream...');
        closeOrganizationSettingsStream();
        openOrganizationSettingsStream();
      }

      if (connectError.code === Code.Unauthenticated) {
        navigate(PATHS.signIn, { state: { from: location?.pathname } });
      }

      if (connectError.code === Code.NotFound) {
        navigate(PATHS.patients);
      }

      // eslint-disable-next-line no-console
      console.error(
        'OrganizationSettingsStream: error: ',
        connectError.message,
        connectError.code,
        connectError.rawMessage,
        connectError,
      );
    }
  };

  const closeOrganizationSettingsStream = () => {
    if (abortController) {
      abortController.abort();
    }
  };

  useEffect(() => {
    if (organizationID) {
      openOrganizationSettingsStream();
    }

    return () => {
      closeOrganizationSettingsStream();
    };
  }, [organizationID]);
};
