import { defineMessages } from 'react-intl';

import { QuestionnaireSteps } from '@/entities/marketing/config/types';

export const interestTypes = defineMessages({
  improveDiagnosticsOf2Dimages: {
    id: 'companyProfiles.ImproveDiagnosticsOf2Dimages',
    defaultMessage: 'Improve diagnostics of 2D images',
  },
  improveDiagnosticsOfCBCTImages: {
    id: 'companyProfiles.ImproveDiagnosticsOfCBCTImages',
    defaultMessage: 'Improve diagnostics of CBCT images',
  },
  segmentationOfCBCTAndIOS: {
    id: 'companyProfiles.SegmentationOfCBCTAndIOS',
    defaultMessage: 'Segmentation of CBCT and IOS',
  },
  superimpositionofIOSSTLAndSegmentedSTLfromCBCT: {
    id: 'companyProfiles.SuperimpositionofIOS(STL)andsegmentedSTLfromCBCT',
    defaultMessage: 'Superimposition of IOS (STL) and segmented STL from CBCT',
  },
  improvePatientsCommunicationAndCaseAcceptance: {
    id: 'companyProfiles.ImprovePatientsCommunicationAndCaseAcceptance',
    defaultMessage: 'Improve patients communication and case acceptance',
  },
  savepractitionerandpatienttime: {
    id: 'companyProfiles.Savepractitionerandpatienttime',
    defaultMessage: 'Save practitioner and patient time',
  },
});

export const stepImagesAltText = defineMessages({
  [QuestionnaireSteps.CompanyName]: {
    id: 'imgAltText.companyName',
    defaultMessage: 'Company name',
  },
  [QuestionnaireSteps.OwnerSpecialization]: {
    id: 'imgAltText.ownerSpecialization',
    defaultMessage: 'Specialization',
  },
  [QuestionnaireSteps.FocusOfInterest]: {
    id: 'imgAltText.focusOfInterest',
    defaultMessage: 'Focus of interest',
  },
});

export const ownerSpecialityType = defineMessages({
  generalPractitioner: {
    id: 'OwnerSpecialityType.GeneralPractitioner',
    defaultMessage: 'General Practitioner',
  },
  oralSurgeon: {
    id: 'OwnerSpecialityType.OralSurgeon',
    defaultMessage: 'Oral Surgeon',
  },
  endodontist: {
    id: 'OwnerSpecialityType.Endodontist',
    defaultMessage: 'Endodontist',
  },
  orthodontist: {
    id: 'OwnerSpecialityType.Orthodontist',
    defaultMessage: 'Orthodontist',
  },
  periodontist: {
    id: 'OwnerSpecialityType.Periodontist',
    defaultMessage: 'Periodontist',
  },
  prosthodontist: {
    id: 'OwnerSpecialityType.Prosthodontist',
    defaultMessage: 'Prosthodontist',
  },
  pediatricDentist: {
    id: 'OwnerSpecialityType.PediatricDentist',
    defaultMessage: 'Pediatric Dentist',
  },
  dentalTechnician: {
    id: 'OwnerSpecialityType.DentalTechnician',
    defaultMessage: 'Dental Technician',
  },
  radiologist: {
    id: 'OwnerSpecialityType.Radiologist',
    defaultMessage: 'Radiologist',
  },
});
