import { FC } from 'react';
import cn from 'classnames';
import { useIntl } from 'react-intl';

import {
  ProductModel_SubscriptionKind,
  ProductState,
  ProductStateStatus,
} from '@/shared/api/protocol-ts/api/billing_new/dto_product_new_pb';

import { ServiceView } from '../ServiceView/ServiceView';
import {
  packageStatusDefaultMessages,
  subscriptionStatusDefaultMessages,
} from '../../config/i18n';
import { ProductStatus } from '../../config/types';

import styles from './InventoryItemView.module.scss';

type InventoryItemViewProps = {
  cost?: string;
  inventoryItem: ProductState;
  className?: string;
  testID?: string;
};

const statusStyles: Record<ProductStatus, string> = {
  [ProductStateStatus.ProductStateStatusActive]: styles.active,
  [ProductStateStatus.ProductStateStatusLifeTimeIsOver]: styles.expired,
  [ProductStateStatus.ProductStateStatusDeleted]: styles.blocked,
  [ProductStateStatus.ProductStateStatusAwaitingActivation]:
    styles.awaitingActivation,
};

export const InventoryItemView: FC<InventoryItemViewProps> = (props) => {
  const { className, testID, inventoryItem, cost } = props;

  const { formatMessage } = useIntl();

  const isSubscription = inventoryItem?.Model?.Kind.case === 'Subscription';

  const isAnnualSubscription = isSubscription
    ? Number(
        (inventoryItem?.Model?.Kind?.value as ProductModel_SubscriptionKind)
          ?.LifeTimeMonths ?? 0,
      ) === 12
    : false;

  const subscriptionPeriod = isAnnualSubscription
    ? formatMessage({
        id: 'subscription.perYear',
        defaultMessage: 'per year',
      })
    : formatMessage({
        id: 'subscription.perMonth',
        defaultMessage: 'per month',
      });
  const status = inventoryItem?.Status as ProductStatus;

  return (
    <div data-testid={testID} className={cn(styles.container, className)}>
      <div className={styles.title}>
        <div className={styles.statusBlock}>
          <h5 className={cn(styles.subscriptionName, 'h5')}>
            {inventoryItem?.Model?.Name}
          </h5>

          <div className={cn(styles.status, statusStyles[status])}>
            {isSubscription
              ? formatMessage(subscriptionStatusDefaultMessages[status])
              : formatMessage(packageStatusDefaultMessages[status])}
          </div>
        </div>

        {!!cost && (
          <div className={cn(styles.price, 'p1')}>
            {cost} {isSubscription && subscriptionPeriod}
          </div>
        )}
      </div>

      <div className={styles.services}>
        {inventoryItem?.Services?.length > 0 &&
          inventoryItem?.Services?.filter((service) => service.Visible)?.map(
            (service) => (
              // TODO: [2/m] Think about key
              <ServiceView
                key={`${service.Group}-${service.Types}`}
                service={service}
              />
            ),
          )}
      </div>
    </div>
  );
};
