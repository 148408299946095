import { FC, useEffect, useState } from 'react';
import cn from 'classnames';
import { FormattedMessage, useIntl } from 'react-intl';

import { Button, WidgetCard, Spinner } from '@/shared/ui';
import { useAppDispatch, useAppSelector } from '@/shared/hooks';

import { reportsModel, useCheckReportSignature } from '@/entities/reports';
import { userModel } from '@/entities/user';

import { Editor } from '../Editor/Editor';
import { getEditorContentFromValue } from '../../lib/getEditorContentFromValue';
import { getContentWithoutHTML } from '../../lib/getContentWithoutHTML';
import { getDraftJsStyles } from '../../lib/getDraftJsStyles';
import { getHTMLWithStyles } from '../../lib/getHTMLWithStyles';
import { ReportSigns } from '../ReportSigns/ReportSigns';
import { AddSignature } from '../AddSignature/AddSignature';

import styles from './Conclusion.module.scss';

type ConclusionProps = {
  className?: string;
};

export const PreviewConclusion: FC<ConclusionProps> = (props) => {
  const { className } = props;
  const [html, setHtml] = useState<string>('');
  const [loading, setLoading] = useState(true);

  const report = useAppSelector(reportsModel.selectors.selectCurrentReport);
  const conclusion = report?.LegacyConclusion || '';

  useEffect(() => {
    const loadContent = async () => {
      try {
        setLoading(true);
        const { stateToHTML } = await import('draft-js-export-html');
        const editorContent = await getEditorContentFromValue(conclusion);
        const htmlWithoutAlign = stateToHTML(editorContent);
        const htmlWithStyles = getHTMLWithStyles(conclusion, htmlWithoutAlign);
        setHtml(htmlWithStyles);
      } catch (error) {
        console.error('Error loading conclusion:', error);
      } finally {
        setLoading(false);
      }
    };

    loadContent();
  }, [conclusion]);

  if (loading) {
    return <Spinner />;
  }

  return (
    <div className={cn(styles.preview, className)}>
      <h1 className={styles.previewHeader}>
        <FormattedMessage id="report.conclusion" defaultMessage="Conclusion" />
      </h1>
      <div className={styles.legacyConclusion}>
        <div dangerouslySetInnerHTML={{ __html: html }} />
      </div>
    </div>
  );
};

export const Conclusion: FC<ConclusionProps> = (props) => {
  const { className } = props;
  const [html, setHtml] = useState('');
  const [loading, setLoading] = useState(false);
  const [isEditMode, setIsEditMode] = useState(false);
  const [editorValue, setEditorValue] = useState('');

  const report = useAppSelector(reportsModel.selectors.selectCurrentReport);
  const user = useAppSelector(userModel.selectors.selectCurrentUser);
  const dispatch = useAppDispatch();
  const { formatMessage } = useIntl();

  const conclusion = report?.LegacyConclusion || '';
  const reportID = report?.ID || '';

  const placeholder = formatMessage({
    id: 'report.conclusion.placeholder',
    defaultMessage: 'Leave a conclusion...',
  });

  useEffect(() => {
    const loadContent = async () => {
      try {
        const { stateToHTML } = await import('draft-js-export-html');
        const editorContent = await getEditorContentFromValue(conclusion);
        const htmlWithoutAlign = stateToHTML(editorContent);
        const htmlWithStyles = getHTMLWithStyles(conclusion, htmlWithoutAlign);
        setHtml(htmlWithStyles);
        setEditorValue(htmlWithStyles);
      } catch (error) {
        console.error('Error loading conclusion:', error);
      }
    };

    loadContent();
  }, [conclusion]);

  const text = getContentWithoutHTML(html);

  const { checkReportSignature } = useCheckReportSignature();

  const canAddSign =
    report?.Signature?.UserSignatures.length &&
    !report?.Signature.UserSignatures.find(
      (userSignature) => userSignature.UserID === user.ID,
    ) &&
    report.YourPermissions?.CanSign;

  const handleSign = async () => {
    setLoading(true);

    try {
      const response = await dispatch(
        reportsModel.thunks.signReport({ ReportID: reportID }),
      ).unwrap();

      if (response.Report) {
        dispatch(reportsModel.actions.setNewestOne(response.Report));
      }
    } finally {
      setLoading(false);
    }
  };

  const handleSave = () => {
    checkReportSignature({
      onSignatureChecked: async () => {
        setLoading(true);

        try {
          const contentState = JSON.stringify(
            await getDraftJsStyles(editorValue),
          );

          const result = await dispatch(
            reportsModel.thunks.setReportConclusion({
              ReportID: reportID,
              LegacyConclusion: contentState,
            }),
          ).unwrap();

          if (result.Report) {
            dispatch(reportsModel.actions.setNewestOne(result.Report));
          }
        } catch (e) {
          console.error(e);
        } finally {
          setLoading(false);
          setIsEditMode(false);
        }
      },
    });
  };

  return (
    <WidgetCard className={cn(styles.container, className)}>
      <div className={cn(styles.conclusionContainer, className)}>
        <h1>
          <FormattedMessage
            id="report.conclusion"
            defaultMessage="Conclusion"
          />
        </h1>
        {text && !isEditMode ? (
          <div className={styles.legacyConclusion}>
            <div dangerouslySetInnerHTML={{ __html: html }} />
            <Button
              size="small"
              variant="secondary"
              onClick={() => setIsEditMode(true)}
              className={styles.editButton}
            >
              <FormattedMessage id="global.edit" defaultMessage="Edit" />
            </Button>
          </div>
        ) : (
          <div className={styles.editorContainer}>
            <Editor
              value={editorValue}
              setValue={setEditorValue}
              placeholder={placeholder}
            />
            <div className={styles.buttonsContainer}>
              {text && (
                <Button
                  size="small"
                  variant="tertiary"
                  onClick={() => setIsEditMode(false)}
                >
                  <FormattedMessage
                    id="global.cancel"
                    defaultMessage="Cancel"
                  />
                </Button>
              )}
              <Button
                size="small"
                variant="secondary"
                onClick={handleSave}
                loading={loading}
                disabled={
                  editorValue === html ||
                  (!text && !getContentWithoutHTML(editorValue).trim())
                }
              >
                <FormattedMessage id="global.save" defaultMessage="Save" />
              </Button>
            </div>
          </div>
        )}
      </div>

      {report?.Signature?.UserSignatures.length ? (
        <ReportSigns signedUsers={report.Signature.UserSignatures} />
      ) : null}

      {canAddSign ? (
        <AddSignature user={user} loading={loading} handleSign={handleSign} />
      ) : null}
    </WidgetCard>
  );
};
