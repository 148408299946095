import { useIntl } from 'react-intl';

import optionDefaultImage from '@/shared/assets/images/StudyCardPlaceholder.png';
import { Study } from '@/shared/api/protocol-ts/model/dto_study_pb';

import { getImageSrc } from '@/entities/assets';

import styles from '../ui/studiesOptions.module.scss';

export const useGetOptionsFromStudies = (studies: Study[]) => {
  const { formatMessage, formatDate } = useIntl();

  return studies.map((study) => {
    const imgSrc = study.DisplayAssetID
      ? getImageSrc(study.DisplayAssetID, 'preview')
      : optionDefaultImage;

    const createDate = formatDate(study.Created?.At?.toDate() ?? 0, {
      day: 'numeric',
      month: 'long',
      year: 'numeric',
    });

    return {
      label: (
        <div className={styles.selectOption}>
          <img
            src={imgSrc}
            alt={formatMessage({
              id: 'Order3dSegmentronModal.optionAlt',
              defaultMessage: 'Study',
            })}
          />
          <p className="p2">{createDate}</p>
        </div>
      ),
      value: study.ID,
    };
  });
};
