import { InferType, array, object, string } from '@/shared/config/yup';

import { QuestionnaireSteps } from '@/entities/marketing/config/types';

const step1Schema = object({
  CompanyName: string().required(),
});

type Step1Schema = InferType<typeof step1Schema>;

const step2Schema = object({
  OwnerSpecialization: string().required(),
});

type Step2Schema = InferType<typeof step2Schema>;

const step7Schema = object({
  FocusOfInterest: array().of(string()).min(1).nullable().required(),
});

type Step7Schema = InferType<typeof step7Schema>;

export const additionalInfoFormSchema = {
  [QuestionnaireSteps.CompanyName]: step1Schema,
  [QuestionnaireSteps.OwnerSpecialization]: step2Schema,
  [QuestionnaireSteps.FocusOfInterest]: step7Schema,
};

export type AllStepsSchema = Step1Schema | Step2Schema | Step7Schema;
