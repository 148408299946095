import { groupBy } from 'lodash-es';

import { Asset } from '@/shared/api/protocol-ts/model/dto_asset_pb';
import { MedicalImageInterface } from '@/shared/config';
import { Localization } from '@/shared/api/protocol-ts/model/dto_report_localization_pb';
import { localizationToPerioMasks } from '@/shared/lib';

import {
  GroupConfigsType,
  PathologyGroup,
} from '../config/medicalImages.types';

import { getFileSrc } from './getFileSrc';
import { makeAnnotations } from './makeAnnotations';
import { makeImageViewOptions } from './makeImageViewOptions';

export const makePathologyGroup = (
  groupConfigs: GroupConfigsType,
  medicalImagesList: Asset[],
  masks?: Localization[],
): PathologyGroup => {
  const { groupName, groupCode } = groupConfigs;

  const groupedByAssetIDMasks = groupBy(masks, 'TargetAssetID');

  const medicalImages = medicalImagesList.reduce((acc, item) => {
    const generatedReportAsset =
      item.Kind.case === 'GeneratedReport' ? item.Kind.value : undefined;
    const reportAsset =
      item?.Kind.case === 'Report' ? item.Kind.value : undefined;

    const annotations = makeAnnotations(
      generatedReportAsset?.MedicalImageFeatures?.Annotations?.Layers ??
        reportAsset?.MedicalImageFeatures?.Annotations?.Layers,
    );

    const viewOptions = makeImageViewOptions(
      generatedReportAsset?.MedicalImageFeatures?.ViewOptions ??
        reportAsset?.MedicalImageFeatures?.ViewOptions,
    );

    if (
      generatedReportAsset?.Content.case === 'CBCTToothPathologySlice' &&
      generatedReportAsset.Content.value.Pathology === groupCode
    ) {
      const pathologySlice = generatedReportAsset.Content.value;

      acc.push({
        id: pathologySlice.Slice?.ID || '',
        src: getFileSrc(pathologySlice.Slice?.ID || ''),
        path: pathologySlice.Slice?.Path,
        assetID: item.ID,
        masks: groupedByAssetIDMasks[item.ID]?.flatMap(
          localizationToPerioMasks,
        ),
        groupName,
        annotations,
        viewOptions,
      });
    }

    return acc;
  }, [] as MedicalImageInterface[]);

  return {
    type: groupName,
    medicalImages,
  };
};
