import React, { Suspense } from 'react';
import { PulseLoader } from 'react-spinners';

import { DatePickerProps } from './DatePicker';
import styles from './DatePicker.module.scss';

const DatePickerLazy = React.lazy(() =>
  import('./DatePicker').then((module) => ({
    default: module.DatePicker,
  })),
);

export const DynamicDatePicker: React.FC<DatePickerProps> = (props) => {
  return (
    <Suspense
      fallback={
        <div className={styles.container}>
          <PulseLoader size={8} />
        </div>
      }
    >
      <DatePickerLazy {...props} />
    </Suspense>
  );
};
