import { useEffect, useState } from 'react';

import { SelectOptionType } from '@/shared/config';

import { getAvailableLanguages } from '../lib/getAvailableLanguages';

type Languages = Record<string, string[]>;

const getLanguages = async (): Promise<Languages> =>
  import('../localizations/languages.json').then(
    (languages) => languages.default,
  );

const transformLanguages = (languages: Languages) =>
  Object.keys(languages).map((langKey) => {
    const [, languageNativeName] = languages[langKey];
    return {
      value: langKey,
      label: languageNativeName,
    };
  });

// hook returns two arrays because InviteSignup requires "languageOptions"
export const useLanguages = () => {
  const [languageOptions, setLanguageOptions] = useState<SelectOptionType[]>(
    [],
  );

  useEffect(() => {
    const fetchLanguages = async () => {
      try {
        const langs = await getLanguages();

        const availableLangs = getAvailableLanguages();

        const transformedLangs = transformLanguages(langs);

        const filtteredByAvailabilityLangs = transformedLangs.filter(
          (langOption) => availableLangs.includes(langOption.value),
        );

        setLanguageOptions(filtteredByAvailabilityLangs);
      } catch (error: unknown) {
        // eslint-disable-next-line no-console
        console.error(error);
      }
    };

    fetchLanguages();
  }, []);

  return { languageOptions };
};
