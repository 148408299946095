import {
  createSlice,
  createEntityAdapter,
  PayloadAction,
} from '@reduxjs/toolkit';
import { difference } from 'lodash-es';

import { Tooth } from '@/shared/api/protocol-ts/model/dto_report_tooth_pb';
import {
  DefaultInitialState,
  LoadingStateType,
  SliceName,
} from '@/shared/config';

import { sortByISONumberOrder } from '../lib/sortByISONumbersOrder';

import { updateCurrentReportROI } from './toothSlice.thunks';

export const toothAdapter = createEntityAdapter<Tooth>({
  selectId: (tooth: Tooth) => tooth.ID,
  sortComparer: (a, b) =>
    sortByISONumberOrder(a.Numeration?.ISO ?? 0, b.Numeration?.ISO ?? 0),
});

type InitialToothState = DefaultInitialState & {
  localROITeethISONumbers: number[];
  localROITeethIDs: string[];
  toothChartCustomMode: boolean;
  updatingROI: boolean;
};

const initialState: InitialToothState = {
  localROITeethISONumbers: [],
  localROITeethIDs: [],
  toothChartCustomMode: false,
  updatingROI: false, // TODO: use object with loading statuses?
  loading: 'idle',
};

const toothState = toothAdapter.getInitialState(initialState);

type ToothState = typeof toothState;

const setNewestOne = (state: ToothState, action: PayloadAction<Tooth>) => {
  const currentRevisionNumber =
    state.entities[action.payload.ID]?.Revision?.Number ?? 0;
  const payloadRevisionNumber = action.payload.Revision?.Number ?? 0;

  if (payloadRevisionNumber > currentRevisionNumber) {
    toothAdapter.setOne(state, action.payload);
  }
};

const setNewestMany = (state: ToothState, action: PayloadAction<Tooth[]>) => {
  const newestTeeth = action.payload.filter((tooth) => {
    const currentRevisionNumber =
      state.entities[tooth.ID]?.Revision?.Number ?? 0;
    const payloadRevisionNumber = tooth.Revision?.Number ?? 0;
    return payloadRevisionNumber > currentRevisionNumber;
  });

  if (newestTeeth.length > 0) {
    toothAdapter.setMany(state, newestTeeth);
  }
};

const toothSlice = createSlice({
  name: SliceName.tooth,
  initialState: toothState,
  reducers: {
    addOne: (state, action: PayloadAction<Tooth>) =>
      toothAdapter.addOne(state as ToothState, action.payload),
    addMany: (state, action: PayloadAction<Tooth[]>) =>
      toothAdapter.addMany(state as ToothState, action.payload),
    setOne: (state, action: PayloadAction<Tooth>) =>
      toothAdapter.setOne(state as ToothState, action.payload),
    setMany: (state, action: PayloadAction<Tooth[]>) =>
      toothAdapter.setMany(state as ToothState, action.payload),
    removeOne: (state, action: PayloadAction<string>) =>
      toothAdapter.removeOne(state as ToothState, action.payload),
    removeAll: (state) => toothAdapter.removeAll(state as ToothState),
    setNewestOne: (state, action: PayloadAction<Tooth>) =>
      setNewestOne(state as ToothState, action),
    setNewestMany: (state, action: PayloadAction<Tooth[]>) =>
      setNewestMany(state as ToothState, action),
    setLocalROITeethISONumbers_DEPRECATED: (
      state,
      action: PayloadAction<number[]>,
    ) => {
      state.localROITeethISONumbers = action.payload;
    },
    toggleLocalROITeethIDs: (state, action: PayloadAction<string[]>) => {
      const { localROITeethIDs } = state;
      const teethIDs = action.payload;

      const isAllReceivedIDsInROI = teethIDs.every((toothID) =>
        localROITeethIDs.includes(toothID),
      );
      const newROITeethIDs = isAllReceivedIDsInROI
        ? difference(localROITeethIDs, teethIDs)
        : [...localROITeethIDs, ...teethIDs];

      state.localROITeethIDs = newROITeethIDs;
    },
    addLocalROITeethIDs: (state, action: PayloadAction<string[]>) => {
      const newTeethIds = action.payload.filter(
        (toothId) => !state.localROITeethIDs.includes(toothId),
      );
      state.localROITeethIDs = [...state.localROITeethIDs, ...newTeethIds];
    },
    removeLocalROITeethIDs: (state, action: PayloadAction<string[]>) => {
      state.localROITeethIDs = state.localROITeethIDs.filter(
        (toothId) => !action.payload.includes(toothId),
      );
    },
    initLocalROITeethIDs: (state, action: PayloadAction<string[]>) => {
      state.localROITeethIDs = action.payload;
    },
    setToothChartCustomMode: (state, action: PayloadAction<boolean>) => {
      state.toothChartCustomMode = action.payload;
    },
    setUpdatingROI: (state, action: PayloadAction<boolean>) => {
      state.updatingROI = action.payload;
    },
    setLoading: (state, action: PayloadAction<LoadingStateType>) => {
      state.loading = action.payload;
    },
    setNewCommentOptimistic: (
      state,
      { payload }: PayloadAction<{ toothID: string; comment: string }>,
    ) => {
      const tooth = state.entities[payload.toothID];

      if (tooth) {
        tooth.Comment = payload.comment;
        state.entities[tooth.ID] = tooth;
      }
    },
    reset: () => toothAdapter.getInitialState(initialState),
  },
  extraReducers: (builder) => {
    builder.addCase(updateCurrentReportROI.pending, (state) => {
      state.updatingROI = true;
    });

    builder.addCase(updateCurrentReportROI.fulfilled, (state, action) => {
      state.updatingROI = false;
      toothAdapter.setMany(state as ToothState, action.payload.AllReportTeeth);
    });

    builder.addCase(updateCurrentReportROI.rejected, (state) => {
      state.updatingROI = false;
    });
  },
});

export const { actions } = toothSlice;

export default toothSlice.reducer;
