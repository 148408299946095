import { FC, useCallback, useEffect, useRef, useState } from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import { useParams } from 'react-router';
import cn from 'classnames';

import { Button, Modal } from '@/shared/ui';
import { useAppDispatch, useAppSelector } from '@/shared/hooks';
import { ToothNumeration } from '@/shared/api/protocol-ts/model/dto_report_common_pb';
import { checkDuplicatesInLocalizations } from '@/shared/lib';
import { SUPERNUMERARY_INDEX_BY_USER } from '@/shared/config';

import { ModalID, modalModel } from '@/entities/modal';
import { reportsModel, useCheckReportSignature } from '@/entities/reports';
import {
  LocalizationWithISO,
  TeethNumbersPopup,
  toothModel,
} from '@/entities/tooth';
import { organizationModel } from '@/entities/organization';
import { assetsModel, getImageSrc } from '@/entities/assets';

import { ImageWithTeethNumbers } from '@/features/ImageWithTeethNumbers';

import styles from './PanoChangeTeethNumberModal.module.scss';

type PanoChangeTeethNumberModalProps = {
  className?: string;
};

// TODO: [m|8] write universal HOC component for modals to prevent hidden modals rendering
export const PanoChangeTeethNumberModal = () => {
  const { visible } = useAppSelector(
    modalModel.selectors.selectTeethNumberingModal,
  );

  return visible ? <PanoChangeTeethNumberModalComponent /> : null;
};

export const PanoChangeTeethNumberModalComponent: FC<
  PanoChangeTeethNumberModalProps
> = (props) => {
  const { className } = props;

  const { reportID } = useParams();

  const dispatch = useAppDispatch();
  const { formatMessage } = useIntl();

  const { visible } = useAppSelector(
    modalModel.selectors.selectTeethNumberingModal,
  );
  const reportTeeth = useAppSelector(
    toothModel.selectors.selectByReportID(reportID as string),
  );
  const dentalNotationFormat = useAppSelector(
    organizationModel.selectors.selectDentalNotationFormat,
  );
  const panoAssetID = useAppSelector(
    reportsModel.selectors.selectCurrentReportPanoAssetID,
  );
  const panoAsset = useAppSelector(
    assetsModel.selectors.selectAssetByID(panoAssetID as string),
  );
  const deletedTooth404 = useAppSelector(
    toothModel.selectors.select404ByReportID(reportID as string),
  );

  const [selectedLocalization, setSelectedToothLocalization] = useState<
    LocalizationWithISO | undefined
  >(undefined);
  const [selectedAssetISONumbers, setSelectedAssetISONumbers] = useState<
    number[] | undefined
  >(undefined);
  const [isToothRemoving, setIsToothRemoving] = useState(false);
  const [animatedToothNumber, setAnimatedToothNumber] = useState<number>(0);
  const [panoScaledSize, setPanoScaledSize] = useState({
    width: 0,
    height: 0,
    scale: 0,
  });

  const panoContainerRef = useRef<HTMLDivElement>(null);

  const { checkReportSignature } = useCheckReportSignature();

  const allReportTeeth = deletedTooth404
    ? [...reportTeeth, deletedTooth404]
    : reportTeeth;

  const localizationItems = allReportTeeth.flatMap((tooth) =>
    tooth.Detections.map((detection) => {
      const localization = detection.Localizations;

      return {
        toothID: tooth.ID,
        isRemoved: tooth?.IsRemoved,
        ISONumber: tooth.Numeration?.ISO,
        SupernumeraryIndex: tooth.Numeration?.SupernumeraryIndex,
        ...localization,
      };
    }),
  ) as LocalizationWithISO[];

  const title = formatMessage({
    id: 'report.EditTeethNumbers',
    defaultMessage: 'Edit teeth numbers',
  });

  // TODO: [2|m] write selector for pano localizations
  const panoLocalizations = localizationItems.filter(
    (localizationItem) => localizationItem.TargetAssetID === panoAsset?.ID,
  ) as LocalizationWithISO[];

  const disabledByDuplicates =
    checkDuplicatesInLocalizations(panoLocalizations);

  const handleClose = useCallback(() => {
    dispatch(modalModel.actions.closeModal(ModalID.TeethNumberingModal));
  }, [dispatch]);

  const handleToothClick = (localization: LocalizationWithISO | undefined) => {
    setSelectedToothLocalization(localization);
  };

  // TODO?: mb move to separated hook
  const handleChangeToothNumber = (
    toothLocalization: LocalizationWithISO,
    newToothISONumber: number,
  ) => {
    checkReportSignature({
      onSignatureChecked: async () => {
        try {
          setAnimatedToothNumber(newToothISONumber);

          const teethWithSameNumber = allReportTeeth.filter(
            (tooth) =>
              tooth.Numeration?.ISO === newToothISONumber &&
              tooth.ID !== toothLocalization.toothID &&
              !tooth.IsRemoved,
          );

          const supernumeraryIndex =
            teethWithSameNumber.length > 0 ? SUPERNUMERARY_INDEX_BY_USER : 0;

          const { Tooth, Report } = await dispatch(
            reportsModel.thunks.setToothNumerationAndRevive({
              ToothID: toothLocalization.toothID,
              Numeration: {
                ISO: newToothISONumber,
                SupernumeraryIndex: supernumeraryIndex,
              } as ToothNumeration,
            }),
          ).unwrap();

          if (Tooth) {
            dispatch(toothModel.actions.setNewestOne(Tooth));
          }

          if (Report) {
            dispatch(reportsModel.actions.setNewestOne(Report));
          }
          setAnimatedToothNumber(0);
        } finally {
          setSelectedToothLocalization(undefined);
        }
      },
    });
  };

  const handleRemoveTooth = (toothLocalization: LocalizationWithISO) => {
    checkReportSignature({
      onSignatureChecked: async () => {
        setIsToothRemoving(true);
        try {
          const { Tooth, Report } = await dispatch(
            reportsModel.thunks.markToothAsDeleted({
              ToothID: toothLocalization.toothID,
            }),
          ).unwrap();

          if (Tooth) {
            dispatch(toothModel.actions.setNewestOne(Tooth));
          }
          if (Report) {
            dispatch(reportsModel.actions.setNewestOne(Report));
          }
        } finally {
          setSelectedToothLocalization(undefined);
          setIsToothRemoving(false);
        }
      },
    });
  };

  const toothClickHandle = (
    viewedISONumbers: number[],
    localization: LocalizationWithISO,
  ) => {
    setSelectedAssetISONumbers(viewedISONumbers);
    handleToothClick(localization);
  };

  // TODO: move observer to hook
  useEffect(() => {
    const panoResizeObserver = new ResizeObserver((entries) => {
      for (const entry of entries) {
        const { height } = entry.contentRect;

        const panoStudyAsset =
          panoAsset?.Kind.case === 'Study' ? panoAsset.Kind.value : undefined;
        const panoramicXRay =
          panoStudyAsset?.Content.case === 'PanoramicXRay'
            ? panoStudyAsset.Content.value
            : undefined;
        const panoramicXRayImage =
          panoramicXRay?.PanoramaImage?.Metadata.case === 'Image'
            ? panoramicXRay.PanoramaImage.Metadata.value
            : undefined;

        const PANO_CONTAINER_HEIGHT_STYLE = 1; // use for adopt scale to container flex: 0 0 70%

        const scalePanoByOriginalHeight =
          (height / Number(panoramicXRayImage?.Height ?? 1)) *
          PANO_CONTAINER_HEIGHT_STYLE;

        setPanoScaledSize({
          width:
            Number(panoramicXRayImage?.Width ?? 1) * scalePanoByOriginalHeight,
          height,
          scale: scalePanoByOriginalHeight,
        });
      }
    });

    if (panoContainerRef.current) {
      panoResizeObserver.observe(panoContainerRef.current);
    }

    return () => {
      panoResizeObserver.disconnect();
    };
  }, []);

  return (
    <Modal
      title={title}
      isOpen={visible}
      onCancel={handleClose}
      className={styles.modal}
      bodyClassName={styles.modalBody}
      containerClassName={styles.modalContainer}
      hideFooter
      borderless
      shouldRenderCloseIconButton={false}
      shouldCloseOnOverlayClick={!disabledByDuplicates}
      shouldRenderCancelButton={false}
    >
      <div className={cn(styles.container, className)}>
        <div className={styles.matrixContainer} ref={panoContainerRef}>
          <ImageWithTeethNumbers
            className={styles.panoWrap}
            localizations={panoLocalizations}
            src={getImageSrc(panoAsset?.ID as string, 'optimized')}
            onToothClick={toothClickHandle}
            dentalNotation={dentalNotationFormat}
            scale={panoScaledSize.scale}
            style={{
              width: panoScaledSize.width,
              height: panoScaledSize.height,
            }}
          />
        </div>
        <TeethNumbersPopup
          isOpen={!!selectedLocalization}
          onChangeToothNumber={handleChangeToothNumber}
          onChangeSelectedLocalization={handleToothClick}
          title={title}
          selectedToothLocalization={selectedLocalization}
          selectedAssetISONumbers={selectedAssetISONumbers}
          isToothRemoving={isToothRemoving}
          dentalNotationFormat={dentalNotationFormat}
          onRemoveTooth={handleRemoveTooth}
          animatedToothNumber={animatedToothNumber}
        />
      </div>
      <div className={styles.footer}>
        <Button
          size="medium"
          onClick={handleClose}
          className={styles.approveButton}
        >
          <FormattedMessage id="patient.confirm" defaultMessage="Confirm" />
        </Button>
      </div>
    </Modal>
  );
};
