import { FC, useEffect } from 'react';
import { useLocation, useParams } from 'react-router';
import cn from 'classnames';
import { FormattedMessage, useIntl } from 'react-intl';
import { isEmpty } from 'ramda';

import { StudyType } from '@/shared/api/protocol-ts/model/dto_study_pb';
import { Column, Icon, Layout, Tooltip, WidgetLayout } from '@/shared/ui';
import { useAppDispatch, useAppSelector } from '@/shared/hooks';
import { LocationStateType } from '@/shared/config';
import { gender } from '@/shared/i18n';
import { getName, trackPageView } from '@/shared/lib';
import { FeatureFlag } from '@/shared/api/protocol-ts/model/dto_organization_pb';

import { ConfirmModal, ModalID, modalModel } from '@/entities/modal';
import {
  getPatientYourPermissions,
  patientModel,
  useFormatPatientDateOfBirth,
} from '@/entities/patient';
import { filterStudiesByType, studyModel } from '@/entities/study';
import { userModel } from '@/entities/user';
import { organizationModel } from '@/entities/organization';

import { Footer } from '@/features/footer';
import { reportCardsModel } from '@/features/reportCards';

import { Header } from '@/widgets/Header';
import { PatientInfo } from '@/widgets/PatientInfo';
import { PatientModalForm } from '@/widgets/PatientModalForm';
import { GetFullAccessModal } from '@/widgets/GetFullAccessModal';
import { OrderImplantOrOrthoModelModal_DEPRECATED } from '@/widgets/OrderModelModal';
import { Order3DSegmentronModal } from '@/widgets/Order3DSegmentronModal';
import { AboutLabelingModal } from '@/widgets/AboutLabelingModal';
import { OrderReportModal } from '@/widgets/OrderReportModal';
import { ReportCard } from '@/widgets/ReportCard';
import { ReportInfoModal } from '@/widgets/ReportInfoModal';
import { DentalPhotoReportCard } from '@/widgets/DentalPhotoReportCard';
import { OrderReport } from '@/widgets/OrderReport';

import styles from './PatientProfile.module.scss';

export const PatientProfile: FC = () => {
  const { patientID = '' } = useParams();

  const dispatch = useAppDispatch();

  const { formatMessage } = useIntl();

  const location = useLocation();

  const user = useAppSelector(userModel.selectors.selectCurrentUser);
  const userLocale = useAppSelector(userModel.selectors.selectUserLocale);

  const patient = useAppSelector(
    patientModel.selectors.selectPatientByID(patientID),
  );

  const notDeletedStudies = useAppSelector(
    studyModel.selectors.selectNotDeletedStudiesByPatientID(patientID),
  );

  const { leftColumn, rightColumn } = useAppSelector(
    reportCardsModel.selectors.selectStructuredReportsAndStudiesByPatientID(
      patientID,
    ),
  );

  const showDentalPhotosFeature = useAppSelector(
    organizationModel.selectors.selectFeatureFlag(
      FeatureFlag.FeatureFlag_Show_DentalPhotosWidget,
    ),
  );

  const patientDateOfBirth = patient?.PersonalData?.DateOfBirth;

  const formattedPatientAge = useFormatPatientDateOfBirth({
    patientDateOfBirth,
    pluralized: true,
  });

  const { CanEditPatient } = getPatientYourPermissions(patient);

  const locationState = location?.state as LocationStateType;

  const scrollToLastPositionPatientProfile =
    locationState?.lastPositionPatientProfile;

  useEffect(() => {
    if (scrollToLastPositionPatientProfile) {
      window.scrollTo(0, scrollToLastPositionPatientProfile);
    }
  }, [scrollToLastPositionPatientProfile]);

  useEffect(() => {
    trackPageView('PatientProfile Page Viewed');
  }, []);

  // Maybe simpler just group studies by type and render each type when we need?
  const CBCTStudies = filterStudiesByType(StudyType.StudyType_CBCT)(
    notDeletedStudies,
  );
  const IOSStudies = filterStudiesByType(StudyType.StudyType_IOS_Meshes)(
    notDeletedStudies,
  );

  if (isEmpty(user) || patient === undefined) {
    return null;
  }

  const { CanViewStudies, CanRequestReport } =
    getPatientYourPermissions(patient);

  const handleEditPatient = () => {
    dispatch(
      modalModel.actions.openModal({
        modalID: ModalID.PatientModalForm,
        data: {
          patientID,
        },
      }),
    );
  };

  const patientGender = formatMessage(gender[patient?.Gender]);

  const patientExternalID = `${formatMessage({
    id: 'patientInfo.externalID',
    defaultMessage: 'ID',
  })}: ${patient?.ExternalID}`;

  const patientEmail = patient?.PersonalData?.Emails[0];

  return (
    <Layout>
      <Layout.Header>
        <Header />
      </Layout.Header>

      <Layout.Content>
        <Layout.Main className={styles.main}>
          <div className={styles.header}>
            <div className={styles.patientInfo}>
              <h1 className={cn(styles.patientName, 'h3')}>
                {getName(
                  patient.PersonalData?.FirstName,
                  patient.PersonalData?.LastName,
                  { userLocale },
                )}
              </h1>

              <div className={styles.patientInfoDetails}>
                <div className={styles.patientInfoDetailsWrapper}>
                  <p className="p2">{formattedPatientAge}</p>
                  <p className="p2">{patientGender}</p>
                  {patient.ExternalID ? (
                    <p className="p2">{patientExternalID}</p>
                  ) : null}
                </div>
                {patientEmail && (
                  <p className={cn(styles.patientEmail, 'p2')}>
                    {patientEmail}{' '}
                  </p>
                )}
                {CanEditPatient && (
                  <Tooltip.Primary
                    content={
                      <FormattedMessage
                        id="global.edit"
                        defaultMessage="Edit"
                      />
                    }
                  >
                    <Icon
                      name="pen"
                      size={24}
                      onClick={handleEditPatient}
                      className={styles.penIcon}
                    />
                  </Tooltip.Primary>
                )}
              </div>
            </div>
          </div>

          <WidgetLayout>
            <Column className={styles.column}>
              <PatientInfo patientID={patientID} />

              {CanViewStudies &&
                leftColumn.length > 0 &&
                leftColumn.map((reportAndStudies) => (
                  <ReportCard
                    key={reportAndStudies.ID}
                    structuredReportsAndStudies={reportAndStudies}
                  />
                ))}
            </Column>

            <Column className={styles.column}>
              {CanRequestReport && <OrderReport />}

              {CanViewStudies &&
                rightColumn.length > 0 &&
                rightColumn.map((reportAndStudies) => (
                  <ReportCard
                    key={reportAndStudies.ID}
                    structuredReportsAndStudies={reportAndStudies}
                  />
                ))}

              {showDentalPhotosFeature && (
                <DentalPhotoReportCard patientID={patientID} />
              )}
            </Column>
          </WidgetLayout>

          <PatientModalForm />

          <OrderReportModal />

          <ConfirmModal />
        </Layout.Main>
      </Layout.Content>

      <Layout.Footer>
        <Footer />
      </Layout.Footer>

      <ReportInfoModal />

      <AboutLabelingModal />

      <GetFullAccessModal />

      <OrderImplantOrOrthoModelModal_DEPRECATED
        patientID={patientID}
        cbctStudies={CBCTStudies}
        iosStudies={IOSStudies}
      />

      <Order3DSegmentronModal CBCTStudies={CBCTStudies} />
    </Layout>
  );
};
