import { OrganizationUserRoleType } from '@/shared/api/protocol-ts/model/dto_organization_pb';

import { PermissionStatuses } from './i18n';

export type PermissionType =
  | 'Owner'
  | 'Clinical Leader'
  | 'Treating Doctor'
  | 'Non-clinical Member';

export const TREATING_DOCTORS_AVAILABLE_ROLES = [
  OrganizationUserRoleType.OrganizationUserRoleTypeTreatingDoctor,
  OrganizationUserRoleType.OrganizationUserRoleTypeClinicalLeader,
  OrganizationUserRoleType.OrganizationUserRoleTypeOwner,
];

export const PERMISSIONS: Record<PermissionType, PermissionStatuses[]> = {
  Owner: [
    PermissionStatuses['Manage payments and subscriptions'],
    PermissionStatuses['Manage company settings'],
    PermissionStatuses['View and manage staff'],
    PermissionStatuses['Share patients'],
    PermissionStatuses['Manage patient’s reports'],
    PermissionStatuses['View and manage patients'],
  ],
  'Clinical Leader': [
    PermissionStatuses['Share patients'],
    PermissionStatuses['View and manage staff'],
    PermissionStatuses['Manage patient’s reports'],
    PermissionStatuses['View and manage patients'],
  ],
  'Treating Doctor': [
    PermissionStatuses['Share patients'],
    PermissionStatuses['Manage patient’s reports'],
    PermissionStatuses['View and manage patients'],
  ],
  'Non-clinical Member': [PermissionStatuses['View and manage patients']],
};
